import React, {useState} from 'react';
import * as style from './ContactForm.module.scss';
import {StaticQuery, Link, graphql} from 'gatsby';
import { API } from 'aws-amplify';

const ContactForm = () => {
	const [statusMessage, setStatusMessage] = useState('');
  	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [subject, setSubject] = useState('general');
	const [message, setMessage] = useState(''); 

	const submitHandler = async (e) => {
			 e.preventDefault();

			 setLoading(true);
			 try {

				 const parameters = {
					 body: {
						 name,
						 email,
						 phone,
						 subject,
						 message
					 }
				 }

				 await API.post('estfootball', '/contact', parameters);
				 setStatusMessage(`Your message is received! I'll get back to you as soon as possible.`);

			 } catch(error){
				 console.log(error);
				 setStatusMessage(`Something went wrong! Please try again later.`)
			 }

			 setLoading(false);
 }

	 return(
		 <div className={style.wrapper}>
		 	<div className={style.content}>

				{
					loading ?
						<div className={style.loaderContainer}>
							<div className={style.loader}></div>
						</div>


		        : statusMessage ?

						<div className={style.confirmationContainer}>
		            <h1>Thanks for reaching out!</h1>
		            <p>{statusMessage}</p>
								<Link to='/' className={style.confirmationLink}>Go back to Homepage</Link>
		          </div>

		        :

						<form className={style.form}  onSubmit={submitHandler}>
							<h2 className={style.heading}>Contact Us</h2>
							<p className={style.info}>We are looking forward to hearing from you</p>
							<div className={style.inputContainer}>
								<label htmlFor='name' className={style.inputLabel}>Name</label>
								<input type='text' id='name' className={style.input} placeholder='C Ronaldo' onChange={(e) => setName(e.target.value)} required/>
							</div>
							<div className={style.inputContainer}>
								<label htmlFor='email' className={style.inputLabel} value={email}>Email</label>
								<input type='email' id='email' className={style.input} placeholder='soccer@gmail.com' onChange={(e) => setEmail(e.target.value)} required/>
							</div>
							<div className={style.inputContainer}>
								<label htmlFor='phone' className={style.inputLabel}>Phone</label>
								<input type='phone' id='phone' className={style.input} placeholder='--- --- ----' onChange={(e) => setPhone(e.target.value)}/>
							</div>
							<div className={style.inputContainer}>
								<label htmlFor='subject' className={style.inputLabel}>Subject</label>
								<select id='subject' className={style.input} value={subject} onChange={(e) => setSubject(e.target.value)}>
									<option value='general'>General</option>
									<option value='1on1'>1 On 1</option>
									<option value='group'>Group Training</option>
								</select>
							</div>
							<div className={style.textAreaContainer}>
								<label htmlFor='message' className={style.inputLabel}>Message <span className={style.messageInfo}>(Include age, level, goals, how many, etc. for messages regarding training)</span></label>
								<textarea id='message' className={style.textarea} placeholder='My message...' onChange={(e) => setMessage(e.target.value)} required/>
							</div>
							<button type='submit' className={style.button}>Send Message</button>
						</form>
				}

			</div>
		 </div>
	 )
}
export default ContactForm;
