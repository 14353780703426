// extracted by mini-css-extract-plugin
export var button = "ContactForm-module--button--83UcP";
export var confirmationContainer = "ContactForm-module--confirmation-container--2I5xB";
export var confirmationLink = "ContactForm-module--confirmation-link--zKh7L";
export var content = "ContactForm-module--content--ydRiF";
export var form = "ContactForm-module--form--gNR+-";
export var info = "ContactForm-module--info--C1Fce";
export var input = "ContactForm-module--input--GDJE2";
export var inputContainer = "ContactForm-module--input-container--7bReb";
export var inputLabel = "ContactForm-module--input-label--W0ARd";
export var loader = "ContactForm-module--loader--ZhqdL";
export var loaderContainer = "ContactForm-module--loader-container--2OeNh";
export var messageInfo = "ContactForm-module--message-info--cVPjo";
export var spin = "ContactForm-module--spin--r+s7r";
export var textAreaContainer = "ContactForm-module--text-area-container--V2orS";
export var textarea = "ContactForm-module--textarea--UK9Tz";
export var wrapper = "ContactForm-module--wrapper--eo-gW";