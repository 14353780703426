import React from 'react';
import Layout from '../components/Layout/Layout';
import ContactForm from '../components/ContactForm/ContactForm';

const ContactPage = () => {
  return(
    <Layout>
      <ContactForm />
    </Layout>
  )
}

export default ContactPage;
